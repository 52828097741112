<template>
  <div class="report-option-align">
    <div class="border-bottom py-3" v-if="['spaces', 'tenant_rent_management', 'review_rent_changes'].includes(template) && state !== 'initial' && !config.pivot_mode.type">
      <span class="ml-3 font-weight-medium hb-text-night">
        <span>
          <!-- @click="setInitialState" class="hand-pointer" -->
          <!-- <v-icon size="25" color="hb-text-night" class="hand-pointer">mdi-chevron-left</v-icon> -->
          <label class="tab-label hand-pointer">{{ selectedSection.title }}</label>
        </span>
      </span>
    </div>
    <div style="position: relative; height: calc(100% - 50px)">
      <!-- <index v-if="state === 'initial' && !config.pivot_mode.type"  @close="$emit('close')" :config="config" :template="template" @takeAction="takeAction" ></index> -->

      <component
        @cancel="$emit('close')"
        v-if="state !== 'initial' && !config.pivot_mode.type"
        :is="state"
        :template="template"
        :selected="selectedSection"
        :report="report"
        :property="property"
      />
    </div>
  </div>

</template>
<script>
import Index from '../ReportOptionsActions/index.vue';
import CustomVariance from "../ReportOptionsActions/CustomVariance.vue";

export default {
  name: "ReportOptions",
  props: ['config','template', 'report', 'property'],
  components: {
    Index,
    CustomVariance
  },
  data() {
    return {
      state: 'CustomVariance',
      selected: '',
      selectedSection: { key: "CustomVariance", title: "Custom Variance", permission: null},
    }
  },
  methods: {
    // setInitialState(){
    //   this.state = 'initial';
    // },
    // takeAction(selected, section = {}){
    //   if(!selected) {
    //     this.errorSet(this.$options.name, "Please select an action to take");
    //     return;
    //   }
    //   this.selected = selected;
    //   this.state = this.selected;
    //   this.selectedSection = section;
    // }
  }
}
</script>
<style lang=scss scoped>
.report-option-align {
    position: absolute;
    top: 51px;
    bottom: 0;
    padding: 0;
    width: 100%;
    border-top:1px solid #DFE3E8;
  }

  .tab-label {
    position: relative;
    top: 2px;
  }
</style>