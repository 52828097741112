<script>
export default {
    computed: {
        unitNumber() {
            return this.params.value ? `#${this.params.value}` : '-'
        }
    }
}
</script>

<template>
    <div>
        <HbUnitIcon
            small
            color='#101318'
            class="pr-1"
            :type="getUnitTypeLabel(params.data)"
        />
        <span>{{ unitNumber }}</span>
    </div>
</template>