import Vue from 'vue';
import api from '../assets/api.js';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../EventBus.js';
import columnConfigMixin from '../mixins/BI/columnConfigMixin'
import { formatMoneyByCurrency } from '../utils/common'

import CheckboxRenderer from '../components/includes/ReportFunctions/CheckboxRenderer';
import HeaderFilter from '../components/includes/ReportFunctions/HeaderFilter.vue';
import HbUnitIcon from '../aviary/HbUnitIcon.vue';
import ReportToolTip from '../components/BI/ReportToolTip.vue'
import HbStatusGeneral from '../aviary/HbStatusGeneral.vue'

// BCT: Added Footer component
import Footer from '../components/includes/ReportFunctions/Footer.vue'

Vue.component('Footer', Footer);

export const BIToolMixin = {
  name: 'BITool',
  data: () => ({
    unit_id: '',
    contact_id: '',
    prevSort: '',
    checkboxes_disabled: false,
    selected: [],
    un_checked: [],
    active_action: '',
    show_save_report_dialog: false,
    show_chart: false,
    show_export: false,
    edit_columns: false,
    edit_report: false,
    advanced:false,
    export_report: false,
    save_report: false,
    bulk_edit: false,
    toggle_multiple: [],
    filter_toggle: false,
    structure_loaded: false,
    activated:1,
    gridApi: {},
    columnApi: {},
    cachedParams: {},
    overlayNoRowsTemplate: null,
    addInteractionModal: false,
    sendSpaceInfoModal: false,
    viewMaintenanceModal: false,
    searchField: '',
    lastClickedColDef:{},
    gridOptions: {
      defaultColDef: {
        floatingFilter: false,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      columnDefs: null,
      rowData: null,
      rowSelection: 'multiple',
      domLayout: 'autoHeight',
      suppressDragLeaveHidesColumns: true,
      //rowMultiSelectWithClick:true,
      //suppressRowClickSelection:true,
      suppressCellFocus: true,
      rowModelType: 'serverSide',
      cacheBlockSize:  50,
      // maxBlocksInCache: 10,
      rowClass: 'hummingbird-row',
rowClassRules: '',
      suppressContextMenu: true,
      statusBar: null,    // BCT: Added statusBar field
    },
    balanceFields: ['lease_balance', 'invoice_balance'],
    filter_data:{},
    reset_rows:false,
    defaultData: {},
    filter_structure:[],
    grid_loaded: false,
    actions: {
      type: '',
      props: {}
    },
    table_data: [],
    num_results: 0,
    total_count: 0,
    temp_result_count: 0,
    isTotalCount: false,
    report_name: '',
    toolTipExceptions: ['spacegroup_rate_plan_name', 'unit_size', 'rentchange_recent_note' ,'call_voice'],
    last_report_type: '',
    statusTypes: [
      /* Tenant Statuses */
      { color: '#02AD0F', name: 'Current', description: 'Tenant in good standing'}, // also used in space statuses
      { color: '#FFD600', name: 'Pending', description: 'Active Lead has paid but not signed the Agreement'}, // also used in space statuses
      { color: '#FFD600', name: 'Delinquent', description: 'Tenant that is 1+ Days delinquent'},
      { color: '#FFD600', name: 'Suspended', description: 'Rent, Fee or Auction/Lien Process Suspension, Pending Verified Move-Out'},
      { color: '#FB4C4C', name: 'Active Lien', description: 'Tenant has been sent an Pre-Lien or Lien Letter'},
      { color: '#FB4C4C', name: 'Bankruptcy', description: 'Tenant has filed for Bankruptcy (not a status used currently on HB)'},
      { color: '#FB4C4C', name: 'Auction', description: 'Tenant Scheduled in Auction provides an Auction date'},
      { color: '#919EAB', name: 'Lease Closed', description: 'Tenant whose space has been moved out would display a Lease Closed status'},

      /*Space Statuses */
      { color: '#02AD0F', name: 'Available', description: 'In Inventory, ready to lease'},
      { color: '#FFD600', name: 'On-Hold', description: 'When the space is rented from Mariposa, the space goes into an On-Hold status for 15 mins'},
      { color: '#FFD600', name: 'Reserved', description: 'The space is tied to an active lead and is removed from available inventory'},
      { color: '#FFD600', name: 'Future Leased', description: 'When a space is rented for a future move-in date, it would display a status Future Leased'},
      { color: '#919EAB', name: 'Offline', description: 'Space is scheduled for maintenance'},
      { color: '#919EAB', name: 'Deactivated', description: 'When a space is deactivated through Space Management'},
      { color: '#919EAB', name: 'Deleted', description: 'When a space is deleted through Space Management'},
      { color: '#FB4C4C', name: 'Remove Overlock', description: 'Previously delinquent Tenant is now Current requiring either a manual or electronic removal of restrictions'},
      { color: '#FB4C4C', name: 'To Overlock', description: 'There is an active task for the manager to place an overlock on a space'},
      { color: '#FB4C4C', name: 'Overlocked', description: 'Space has manually or electronically restricted tenant access'},
      { color: '#FB4C4C', name: 'Schedule for Auction', description: 'Space is ready to be scheduled for auction'},
      { color: '#FB4C4C', name: 'Scheduled for Auction', description: 'Delinquent Tenant in the final stages of the lien process. Can be On-site Auction or Online Auction (Time span)'},
      { color: '#3D8FFF', name: 'Charity', description: 'Space donated for charitable purpose'},
      { color: '#3D8FFF', name: 'Company', description: 'Space occupied by the company for business purposes'},
      { color: '#3D8FFF', name: 'Leased', description: 'Space is occupied by a tenant'},
      { color: '#3D8FFF', name: 'Tenant Using Lock', description: 'The lock has been given to a tenant and they are using it as their lock'},
      { color: '#FB4C4C', name: 'Assigned Overlock', description: 'The lock is securing to a delinquent space'},


      /* Lead Statuses */
      { color: '#02AD0F', name: 'Converted Lead', description: 'When a lead is converted to a tenant'},
      { color: '#02AD0F', name: 'Converted', description: 'When a lead is converted to a tenant'},
      { color: '#FFD600', name: 'Active Lead', description: 'Tenant that is digitally or manually nurtured'},
      { color: '#FFD600', name: 'Active', description: 'Tenant that is digitally or manually nurtured'},
      { color: '#919EAB', name: 'Retired Lead', description: 'Digitally or manually exhausted'},
      { color: '#919EAB', name: 'Retired', description: 'Digitally or manually exhausted'},
      
      /* Invoice Statuses */
      { color: '#02AD0F', name: 'Paid', description: 'The state that displays an invoice has been fully paid'},
      { color: '#FFD600', name: 'Open', description: 'When an invoice that is not due and has some pending charges'},
      { color: '#919EAB', name: 'Void', description: 'When an invoice is voided'},
      { color: '#919EAB', name: 'Write Off', description: 'When an invoice is written off as a loss'},
      { color: '#FB4C4C', name: 'Past Due', description: 'When an invoice is due or past due and has incomplete or no payments associated with it'},
      
      /* Document Statuses */
      { color: '#02AD0F', name: 'Signed', description: 'When a document is completely executed'},
      { color: '#FFD600', name: 'Signing in Progress', description: 'When the document signing is in progress'},
      { color: '#FFD600', name: 'Ready to Sign', description: 'When a document is generated and ready to be signed'},
      { color: '#3D8FFF', name: 'Generated', description: 'When a document that has no signature is generated'},
      { color: '#3D8FFF', name: 'Uploaded', description: 'When a document is uploaded'},


      /* to be deprectated? still keep just in case*/
      { color: '#FFD600', name: 'Scheduled Move-Out', description: 'Intent to Move-Out'},
      { color: '#FFD600', name: 'Gate Lockout', description: 'Delinquent or problem tenant that has restricted access - Revoke Gate Access, Pending Lease Signature'},
      { color: '#FFD600', name: 'Balance Due', description: 'Lease is closed but there is still a balance due'},

      { color: '#02AD0F', name: 'Complete', description: 'Auction Completed'},
      { color: '#FFD600', name: 'Auction Payment', description: 'Auction Payment Required'},
      { color: '#FB4C4C', name: 'Auction Day', description: 'Auction Day'},
      { color: '#FFD600', name: 'To Move Out', description: 'Space To Move Out after Auction'},
      { color: '#FB4C4C', name: 'Scheduled', description: 'Space scheduled for auction'},
      { color: '#FB4C4C', name: 'To Schedule', description: 'Space needs to be scheduled for auction'},

      /* old */
      /*
        { color: '#919EAB', name: 'Available', description: 'In Inventory, ready to lease'},
        { color: '#02AD0F', name: 'Leased', description: 'Space is occupied by a tenant'},
        { color: '#02AD0F', name: 'Company', description: 'Space occupied by the company for business purposes'},
        { color: '#02AD0F', name: 'Charity', description: 'Space donated for charitable purpose'},
        { color: '#FFD600', name: 'Offline', description: 'Scheduled for Maintenance, Prep Space to make Available'},
        { color: '#FFD600', name: 'Reserved', description: 'Active Lead, Removed from Inventory, On Hold, Hard Reservation'},
        { color: '#FFD600', name: 'Remove Overlock', description: 'Previously delinquent Tenant is now Current requiring either a manual or electronic removal of restrictions'},
        { color: '#FFD600', name: 'Scheduled Move-Out', description: 'Intent to Move-Out'},
        { color: '#FB4C4C', name: 'Overlocked', description: 'Space has manually or electronically restricted tenant access'},
        { color: '#FB4C4C', name: 'Scheduled for Auction', description: 'Delinquent Tenant in the final stages of the lien process. Can be On-site Auction or Online Auction (Time span)'},
        { color: '#FB4C4C', name: 'Bankruptcy', description: 'Tenant is in Bankruptcy'},
        { color: '#FB4C4C', name: 'Active Lien', description: 'Starts with preliminary lien notice and continues through auction sale'},
        { color: '#FFD600', name: 'Gate Lockout', description: 'Delinquent or problem tenant that has restricted access - Revoke Gate Access, Pending Lease Signature'},
        { color: '#FFD600', name: 'Balance Due', description: 'Lease is closed but there is still a balance due'},
        { color: '#FFD600', name: 'Delinquent', description: 'Tenant that is 2+ Days delinquent'},
        { color: '#02AD0F', name: 'Active Lead', description: 'Tenant that is digitally or manually nurtured'},
        { color: '#FFD600', name: 'Suspended', description: 'Rent, Fee or Auction/Lien Process Suspension, Pending Verified Move-Out'},
        { color: '#FFD600', name: 'Pending', description: 'Incomplete Move-In'},
        { color: '#02AD0F', name: 'Current', description: 'Tenant in good standing'},
        { color: '#919EAB', name: 'Retired Lead', description: 'Digitally or manually exhausted'},
        { color: '#919EAB', name: 'Lease Closed', description: 'previous Tenant with or without balance'},
        { color: '#FB4C4C', name: 'Auction', description: 'Delinquent Tenant in the final stages of the lien process. Can be On-site Auction or Online Auction (Time span)'},
      */

    ],
    sendMessageDialog: false,
    modules: {},
    active_report_id: null,
    active_view_id: null,
    report_template: null,
    componentName: '',
selectedRows: []
  }),
  props: {
    bulkCommunication:      { type: Boolean, default: false },
    show_title:             { type: Boolean, default: false },
    actions_panel:          { type: Array, default: () => [] },
    show_result_count:      { type: Boolean, default: false },
    show_views:             { type: Boolean,  default: false },
    show_checkboxes:        { type: Boolean, default: false },
    show_pagination:        { type: Boolean, default: false },
    show_divider:           { type: Boolean, default: true },
    hide_back_link:         { type: Boolean, default: false },
    show_property_selector: { type: Boolean, default: false },
    gridConf: {
      type: Object,
      default: () => ({
        props: {},
        listeners: {}
      })
    },
    report_type: {
      type: String,
      required: true
    },
    right_click: {
      type: Boolean,
      default: false
    },
    report_id: {
      type: String
    },
    report_filters: {
      type: Object
    },
    default_column_filters: {
      type: Array,
      default: () => []
    },
    default_search_filters: {
      type: Array,
      default: () => []
    },
property: {
      type: String,
      default: "",
    },
    left_slot: {
      type: Boolean,
    },
    no_cell_click: {
      type: Boolean
    },
    row_click: {
      type: String
    },
    show_search: {
      type: Boolean
    },
    disable_header_filters: {
      type: Boolean
    },
    conditions: {
      type: Object
    },
    show_default_report_option: {
      type: Boolean
    },
    columnConf: {
      type: Object,
      default: null
    },
    actionButtonLabel: {
      type: String
    }
  },
  mixins: [columnConfigMixin],
  components:{
    HeaderFilter,
    HbUnitIcon,
    HbStatusGeneral,
    Footer,
    CheckboxRenderer,
    ReportToolTip,
  },

  async created(){
    EventBus.$on("reset_grid_count_values", () => {
      this.total_count = 0;
      this.resetGridRowsCount(false);
    });
    this.gridOptions.suppressContextMenu = !this.right_click;

    // I have a report ID // passed through URL
    // I have a report template // passed through URL
    
    let r = await this.$store.dispatch('reportStore/loadReportStructure', {
      report_id: this.report_id,
      report_template: this.report_type,
      report_filters: this.report_filters
    });

    this.report_template = r.report_type;

    this.isTotalCount = r.isTotalCount

    // this.searchField = this.report.filters.search.search || '';
    if( r.id) {
      this.active_report_id = r.id;
    }

    if(!this.views.length){
      await this.getReportViews(this.report_template );
    }

    // load if there is no id, load the default view

    if(!this.active_report_id) {
      let default_report = this.default_view({
        report_template: this.report_template,
        use_template: !this.show_default_report_option
      });
      this.active_report_id = default_report.id;
      this.searchField = default_report.filters.search.search;

    }

    // dont save view for 'on the fly reports' only saved and 'view' reports

    if(this.show_views || (this.active_report_id && this.active_report_id !== 'template')){
      this.$store.commit('reportStore/setCurrentView', {
        report_template: this.report_template,
        report_id: this.active_report_id
      });
    }

    // override columns and filters if they've been passed in
    if(this.report_filters){
      this.$store.commit('reportStore/setReportFilters', {
        report_id: this.active_report_id,
        report_template: this.report_template,
        report_filters: this.report_filters
      })
    }

    if(this.default_column_filters.length) {
      this.default_column_filters.forEach(data => {
        this.$store.commit('reportStore/setColumnFilter', {
          report_id: this.active_report_id,
          report_template: this.report_type,
          key: data.id,
          search: data.value
        }); // load with default coloum filter
      })
    }

    this.setColumnDefinition(this.report.filters.columns);

    if (this.default_search_filters.length) {
      let dynamicParams = JSON.parse(JSON.stringify(this.getDynamicRunParam));
      this.$store.commit("reportStore/setDefaultSearchFilterData", {
        template: this.report_type,
        filter: this.default_search_filters,
      });
      this.default_search_filters.forEach((data) => {
        dynamicParams = {
          ...dynamicParams,
          search: {
            ...dynamicParams.search,
            [data.id]: data.value,
          },
        };
      });
  
      this.$store.commit("reportStore/setDynamicRunParam", dynamicParams);
    }
    
        this.report_name = this.report.name;

    this.structure_loaded = true;
    this.overlayNoRowsTemplate = '<span style="font-size: 20px;padding: 10px;">Nothing to show here.</span>';
    // BCT: Set Footer status bar
    if (['active_tenants','spaces', 'space_group_spaces', 'tenant_rent_management'].includes(this.report_type)) {
      this.setFooter();
    }
  },

  computed:{
    ...mapGetters({
      properties: 'propertiesStore/filtered',
      report_by_id: 'reportStore/get_report_by_id',
      structure_by_report_type: 'reportStore/getStructure',
      views_list: 'reportStore/views',
      default_view: 'reportStore/get_default_view',
      searching: 'reportStore/searching',
      selected_entries: 'reportStore/get_selected_entries',
      getSearchParams: 'reportStore/getSearchParams',
getReportColumnKeysWithoutIdFields: 'reportStore/getReportColumnKeysWithoutIdFields',
      getDynamicRunParam: 'reportStore/geDynamicRunParam',
      getTenantReportViews: 'reportStore/getTenantReportViews',
      getDefaultSearchFilter: 'reportStore/getDefaultSearchFilter',
      getRefreshGridEnable: 'reportStore/getRefreshGridEnable',
    }),
    isClean(){
      if(!this.report) return true;
      return JSON.stringify(this.report.filters.columns) === JSON.stringify(this.report.defaultData.columns) &&
        JSON.stringify(this.report.filters.groups) === JSON.stringify(this.report.defaultData.groups) &&
        JSON.stringify(this.report.filters.pivot_mode) === JSON.stringify(this.report.defaultData.pivot_mode) &&
        JSON.stringify(this.report.filters.search) === JSON.stringify(this.report.defaultData.search) &&
        JSON.stringify(this.report.filters.sort) === JSON.stringify(this.report.defaultData.sort)
    },

    hasFilters(){
      let { search,  ...filters } = this.report.filters.search;
      return Object.keys(filters).length;
    },

    structure(){
      return this.structure_by_report_type(this.report_template);
    },

    report(){
      console.log("this.active_report_id", this.active_report_id)
      return this.report_by_id({
        report_id: this.active_report_id,
        report_template: this.report_template
      });
    },

    cachedSelections(){
      return this.selected_entries({
        report_id: this.active_report_id,
        report_template: this.report_template
      });
    },

    views(){
      return this.views_list(this.report_template);
    },

    description(){
      let format = 'dddd, MMMM Do YYYY';
      let reportDateFromStore = this.getDynamicRunParam?.timePeriodConfig?.report_date;
      let reportDate = this.report?.filters?.search?.report_date;

      if(reportDateFromStore && reportDate)
        return moment(reportDateFromStore).format(format);

      let reportPeriodFromStore = this.getDynamicRunParam?.timePeriodConfig?.report_period;
      let reportPeriod = this.report?.filters?.search?.report_period;

      if(!reportPeriodFromStore?.label || !reportPeriod?.label) return '';

      let reportPeriodFromStoreLabel = reportPeriodFromStore.label.toLowerCase();
      let reportPeriodLabel = reportPeriod.label.toLowerCase();

      switch(reportPeriodFromStoreLabel || reportPeriodLabel){
        case "today":
          return moment().format(format);
        case "yesterday":
          return moment().subtract(1, 'day').format(format);
        case 'last 7 days':
          return moment().subtract(6, 'day').startOf('day').format(format) + ' - ' + moment().endOf('day').format(format)
        case 'last 30 days':
          return moment().subtract(29, 'day').startOf('day').format(format) + ' - ' + moment().endOf('day').format(format)
        case 'this month':
          return moment().startOf('month').startOf('day').format(format) + ' - ' + moment().endOf('day').format(format)
        case 'last month':
          return moment().subtract(1, 'month').startOf('month').startOf('day').format(format) + ' - ' + moment().subtract(1, 'month').endOf('month').startOf('day').format(format)
        case 'year to date':
          return moment().startOf('year').startOf('day').format(format) + ' - ' + moment().endOf('day').format(format)
        case 'custom range':
          if(reportPeriodFromStore.start_date && reportPeriodFromStore.end_date){
            return  moment(reportPeriodFromStore.start_date).startOf('day').format(format) + ' - ' + moment(reportPeriodFromStore.end_date).startOf('day').format(format);
          }else if(reportPeriod.start_date && reportPeriod.end_date) {
            return  moment(this.report.filters.search.report_period.start_date).startOf('day').format(format) + ' - ' + moment(this.report.filters.search.report_period.end_date).startOf('day').format(format);
          }

          if(reportPeriodFromStore.start_date){
            return 'From ' + moment(reportPeriodFromStore.start_date).startOf('day').format(format);
          }else if(reportPeriod.start_date) {
            return 'From ' + moment(this.report.filters.search.report_period.start_date).startOf('day').format(format);
          }

          if(reportPeriodFromStore.end_date){
            return 'Until ' +  moment(reportPeriodFromStore.end_date).startOf('day').format(format);
          }else if(reportPeriod.end_date) {
            return 'Until ' +  moment(this.report.filters.search.report_period.end_date).startOf('day').format(format);
          }
      }
    },

    pivot_mode_enabled(){
      return this.report.filters && this.report.filters.pivot_mode && this.report.filters.pivot_mode.type;
    },

  getReportBaseLabel() {
      if(this.getTenantReportViews.includes(this.report_type)){
        return 'Tenants'
      }
      return 'Spaces'
    },
    largeDrawer () {
      if(this.getTenantReportViews.includes(this.report_type)){
        return true
      }
      return false 
    }

  },

  methods:{
    ...mapActions({
      setRestGridEnableAction: 'reportStore/setRestGridEnableAction'
    }),

    // Get row classes by statuses
    getRowClass(params) {
      return {
        rentchange_row_status: 'caution'
      }
    },

    onFilterChange(params){
      this.resetGridRowsCount();
      this.gridApi.hideOverlay();
      this.purgeCache();
    },

    manageCheckboxes(isDisabled){
      this.checkboxes_disabled = isDisabled;
    },

    showAction(action){
      return this.actions_panel && this.actions_panel.indexOf(action) >= 0;
    },

    purgeCache(){
      if(!this.gridApi || !this.gridApi.refreshServerSide) return;
      this.reset_rows = true;
      this.gridApi.refreshServerSide();
    },

    toggleActionsWindow(type){

      this.active_action = this.active_action !== type ? type : null;
      if(this.active_action !== this.bulk_edit){
        this.resetBulkEditSelection();
      }
      this.setColumnDefinition(this.report.columns);
      this.$refs.HBReportViewer.setColumnDefs(this.gridApi, this.columnDefs);

      this.$nextTick(() => {
        if(!this.active_action){
          // reset column width
          //this.gridApi.sizeColumnsToFit()
          //  this.gridApi.autoSizeColumns();
        }
      })

    },

    // async getStructure() {
    //     this.structure_loaded = true;
    //     this.overlayNoRowsTemplate = '<span style="font-size: 20px;padding: 10px;">Nothing to show here.</span>';
    //   },

    onGridReady(params) {

      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      //this.gridApi.setServerSideDatasource(this);

      if(this.report.filters.search){
        if(this.$props.conditions && this.$props.conditions.filters) {
          if(this.report.filters.search.rate_change_id && !this.$props.conditions.filters.rate_change_id) {
            delete this.report.filters.search.rate_change_id;
          }
          Object.assign(this.report.filters.search, this.$props.conditions.filters);
        }
        this.searchField = this.report.filters.search.search
      }

      if(this.report.filters && this.report.filters.sort && this.report.filters.sort.field) {
        var sort = {
          state: [{
            colId: this.report.filters.sort.field,
            sort: this.report.filters.sort.dir || 'asc',
          }],
          defaultState: { sort: null },
        };
        this.columnApi.applyColumnState(sort);
      }

      this.grid_loaded = true;
    },

    resetGridRowsCount(is_reset = true) {
      this.num_results = 0;
      this.temp_result_count = 0;
      if (is_reset) {
        this.gridApi.setServerSideDatasource(this);
      }
      this.gridApi.ensureIndexVisible(0, 'top');
      this.gridApi.paginationGoToPage(1);
      this.gridApi.purgeInfiniteCache();
      this.gridApi.refreshInfiniteCache();
      this.gridApi.purgeInfiniteCache();
    },


    async getRows(params){
      // Cache params
      this.cachedParams = params;
      this.gridApi.hideOverlay();
      if(!this.report.filters.columns.length) return this.cachedParams.failCallback();
      let sort = {};
      if(params.request.sortModel && params.request.sortModel.length > 0) {
        sort = params.request.sortModel.map(s => {
          this.prevSort = s.sort
          return {
            field: s.colId,
            dir: s.sort
          }
        })[0]
      }
      // In case of report with a specific id
      // else if(this.report.filters.sort && this.report.filters.sort.field) {
      //   sort = this.report.filters.sort
      // }
      else if(this.report.filters.sort && this.report.filters.sort.field) {
        if (this.prevSort === 'desc') sort = this.report.defaultData.sort
        else sort = this.report.filters.sort
      }

      if (this.getDefaultSearchFilter?.template) {
        let dynamicParams = JSON.parse(JSON.stringify(this.getDynamicRunParam));
        // load with default search filter
        if (this.getDefaultSearchFilter?.template === this.report_type)
          this.getDefaultSearchFilter.filter.forEach((data) => {
            dynamicParams = {
              ...dynamicParams,
              search: {
                ...dynamicParams.search,
                [data.id]: data.value,
              },
            };
          });
        else {
          if (this.getDefaultSearchFilter?.filter.length)
            this.getDefaultSearchFilter?.filter.forEach((data) => {
              if (dynamicParams?.search?.[data.id])
                delete dynamicParams.search[data.id];
            });
        }
    
        this.$store.commit("reportStore/setDynamicRunParam", dynamicParams);
      }

      this.$store.commit('reportStore/setSort', {
        report_id: this.report.id,
        report_template: this.report.template,
        offset: this.reset_rows ? 0: params.request.startRow,
        limit: params.request.endRow -  params.request.startRow,
        sort: sort
      });

      try {

        await this.$store.dispatch('reportStore/getData', {
          report_template: this.report.template,
          report_id: this.report.id
        }); // load from cache
        if(!this.num_results){
          await this.$store.dispatch("reportStore/getDataResultCount", {
            report_template: this.report.template,
            report_id: this.report.id
          });

          this.num_results = this.report.result_count
        }

        this.setColumnDefinition(this.report.columns);
        this.$refs.HBReportViewer.setColumnDefs(this.gridApi, this.columnDefs);
        this.gridApi.resetRowHeights();


        this.table_data = Object.freeze(this.report.table_data.map(row => row));
          this.reset_rows = false;
          let table_data_length = this.table_data.length;
          let total_count = params.request.startRow + table_data_length;
          if(table_data_length >= 50 ){
            total_count = total_count + 1;
          }
          this.cachedParams.successCallback(this.table_data, total_count);
          //this.gridApi.setRowData(this.table_data)
          if (!table_data_length) {
            this.gridApi.showNoRowsOverlay();
            this.$store.commit('reportStore/setNoTableData', true);
          }
          this.gridApi.redrawRows();
          if(this.$props.conditions && this.$props.conditions.select_all) {
            this.gridApi.forEachNode( (node, index) => {
              node.setSelected(true);
            });

          } else if(this.$props.conditions && this.$props.conditions.tenant_list && this.$props.conditions.tenant_list.length) {
            this.gridApi.forEachNode( (node, index) => {
              if(this.$props.conditions.tenant_list.find(obj => obj.lease_id === node.data.lease_id)) {
                node.setSelected(true);
              } else {
                // console.log("No")
                // node.setSelected(false);
              }
            });
          } else if(this.$props.conditions && this.$props.conditions.unit_list && this.$props.conditions.unit_list.length) {
            this.gridApi.forEachNode( (node, index) => {
              if(this.$props.conditions.unit_list.find(obj => obj.unit_id == node.data.unit_id)) {
                node.setSelected(true);
              }
            });
          }

        if((!this.total_count || this.last_report_type != this.report.template)){
          this.last_report_type = this.report.template;
          await this.$store.dispatch("reportStore/getDataTotalCount", {
            report_template: this.report.template,
            report_id: this.report.id
          });
        }
        this.total_count = this.report.total_count;

        // BCT: Set Footer params and filtered data
        if (['active_tenants','spaces', 'space_group_spaces', 'tenant_rent_management'].includes(this.report_type)) {
          this.setFooterParams();
        }
      } catch(err){
        console.log("err", err);
        this.dataFetchingError = err.indexOf("not authorized for this user") >= 0 ? "Forbidden: You do not have permission to view this report." : err;
        params.failCallback();
      }
          },

    setColumnDefinition(columns) {
      this.columnDefs = null;
      let columnDefs = [];

      if(!this.report) return;
      let cols = columns && columns.length ? columns : this.report.filters.columns;
      columnDefs = cols.map((f, i) => {
        return {
          checkboxSelection: false,
          resizable: true,
          field: f.key,
          colId: f.key,
          type: f.column_type === 'number' || f.column_type === 'money' ? "" : null, // removing type 'numericColumn' to empty
          minWidth:  f.minWidth ?? 75,
          width: this.report.filters.pivot_mode.type ? 100 : parseInt(f.width),
          sort: this.report.filters.sort.field === f.key ? this.report.filters.sort.dir : null,
          // valueGetter: params => this.getCellValue(params, f),
          // autoHeight: f.key.substring(f.key.lastIndexOf('_') + 1) === 'address',
          getRowHeight: f.key.substring(f.key.lastIndexOf('_') + 1) === 'address' ? 60 : 48, 
          headerName: f.label,
          sortable: true,
          headerComponent: 'HeaderFilter',
          headerComponentParams : {
            pivot: this.pivot_mode_enabled,
            filter: f,
            report_id: this.report.id,
            report_template: this.report.template,
            disable_header_filters: this.disable_header_filters,
            column_filter: this.columnConf ? this.columnConf[f.key]?.filter ?? true : true
          },
          tooltipField: (!this.toolTipExceptions.includes(f.key)) ? f.key : '',
          tooltipComponent: 'ReportToolTip',
          menuTabs: ['filterMenuTab'],
          hide: f.hide ?? false,
          headerTooltip: f.header_tool_tip ?? '',
          // { getCellRenderer } declared on columnConfigMixin
          ...this.getCellRenderer(f),
          valueFormatter: params => this.formatCellValue(params, f),
          cellStyle: (params) => this.setCellStyle(params)
        }
      });


      if((!this.report.filters.pivot_mode.type && (this.active_action === 'bulk_edit') || this.bulkCommunication)){
        columnDefs.unshift({
          menuTabs: [],
          lockPosition:true,
          //headerCheckboxSelection: true,
          checkboxSelection: true,
          field: 'select',
          headerName: '',
          headerComponent: 'HeaderFilter',

          // BCT: Added header Param 'checkbox'
          headerComponentParams : {
            checkbox: true,
            handleSelectAll: this.handleSelectAll,
            parentComponentName: this.componentName,
          },
          // BCT: Modify Width from 50 to 60
          width: 60,
          pinned:  'left',
          suppressSizeToFit:true
        });
      }
      this.columnDefs = columnDefs;
    },

  async setSearchParams(search){
      search = search  || JSON.parse(JSON.stringify(this.report.filters.search));
      let searchParams = this.getSearchParams({
        report_template: this.report.template,
        report_id: this.report.id
      });

      for(var key in search) {
        searchParams[key] = search[key]
      }

     // if(search.template === this.report.template){
        this.$store.commit('reportStore/setSearchParams', {
          report_template: this.report.template,
          report_id: this.report.id,
          search: searchParams,
          offset: 0
        });
        this.gridApi.onFilterChanged()
      // }


      // search = search  || JSON.parse(JSON.stringify(this.report.filters.search));
      // search.search = this.searchField || '';
      // this.$store.commit('reportStore/setSearchParams', {
      //   report_template: this.report.template,
      //   report_id: this.report.id,
      //   search:  search,
      //   offset: 0
      // });
      //
      // this.gridApi.onFilterChanged()
      // //this.purgeCache();

    },

    async setSearchParams2(search){

      this.$store.commit('reportStore/setSearchParams', {
        report_template: this.report.template,
        report_id: this.report.id,
        search:  search,
        offset: 0
      });

      this.gridApi.onFilterChanged()

    },

    setSearchParams3(search){
      if(search.template === this.report.template){
        this.$store.commit('reportStore/setSearchParams', {
          report_template: this.report.template,
          report_id: this.report.id,
          search:  search.search,
          offset: 0
        });
        this.gridApi.onFilterChanged()
      }
    },

    updateView(report){
      this.active_report_id = report.id;

      this.$store.commit('reportStore/setCurrentView', {
        report_template: this.report_template,
        report_id: this.active_report_id
      });
      if(this.report.filters.search){
        this.searchField = this.report.filters.search.search
      }
      if(this.report.filters && this.report.filters.sort && this.report.filters.sort.field) {
        var sort = {
          state: [{
            colId: this.report.filters.sort.field,
            sort: this.report.filters.sort.dir || 'asc',
          }],
          defaultState: { sort: null },
        };
        this.columnApi.applyColumnState(sort);
      }

    },

    async getReportViews(){
      await this.$store.dispatch('reportStore/loadViews', {
        report_template: this.report_template
      });
      return;
    },

    async resetReport(){
      this.resetGridRowsCount();
      this.$store.commit('reportStore/resetReport', {
        report_template: this.report.template,
        report_id: this.report.id
      });
      if(this.report.filters.search){
        this.searchField = this.report.filters.search.search
      }
      if(this.report.filters && this.report.filters.sort && this.report.filters.sort.field) {

        var sort = {
          state: [{
            colId: this.report.filters.sort.field,
            sort: this.report.filters.sort.dir || 'asc',
          }],
          defaultState: { sort: null },
        };
        this.columnApi.applyColumnState(sort);
      }

      this.setColumnDefinition();
      this.activated++;

    },

    async changeView(report){
      this.resetGridRowsCount();
      this.active_report_id = report.id;

      this.$store.commit('reportStore/setCurrentView', {
        report_template: this.report_template,
        report_id: this.active_report_id
      });
      if(this.report.filters.search){
        this.searchField = this.report.filters.search.search
      }
      if(this.report.filters && this.report.filters.sort && this.report.filters.sort.field) {
        var sort = {
          state: [{
            colId: this.report.filters.sort.field,
            sort: this.report.filters.sort.dir || 'asc',
          }],
          defaultState: { sort: null },
        };
        this.columnApi.applyColumnState(sort);
      }
      this.setColumnDefinition();
      this.activated++;
    },

    setColumns(data){

      this.$store.commit('reportStore/setColumns', {
        report_template: this.report.template,
        report_id: this.report.id,
        columns: data
      });


      // this.filters.pivot_mode = data.pivot_mode;
      // this.filters.columns = data &&  data.columns ? data.columns: this.filters.columns;
      this.gridApi.onFilterChanged()
    },

    setPivot(data){

      this.$store.commit('reportStore/setPivot', {
        report_template: this.report.template,
        report_id: this.report.id,
        pivot_mode: data.pivot_mode
      });


      // this.filters.pivot_mode = data.pivot_mode;
      // this.filters.columns = data &&  data.columns ? data.columns: this.filters.columns;
      this.gridApi.onFilterChanged()
    },

    setPagination(page){
      this.$store.commit('reportStore/setPagination', {
        report_template: this.report.template,
        report_id: this.report.id,
        page: page,
        offset: +this.report.filters.page === 0 ? 0 : ((+this.report.filters.page-1) * this.report.filters.limit)
      });

      // this.filters.page = page;
      // this.filters.offset = +this.filters.page === 0 ? 0 : ((+this.filters.page-1) * this.filters.limit);
      this.gridApi.onFilterChanged()
    },

    getContextMenuItems(data){
      let items = [];

      if(!data.node) return;
      // If No Groups - Edit objects
      // If Groups - Merge Contacts, Bulk Edit Units, Properties, Leases, Tenants, Leads
      if(!this.report.filters.pivot_mode.type){
        if(data.node.data.tenant_id){
          items.push({
            name: "Tenants",
            subMenu: [
              {
                name: "Send A Message",
                action: () => this.sendMessage(data.node.data.tenant_id)
              },
              {
                name: "View Tenant Info",
                action: () => this.viewEntity('tenant', data.node.data.tenant_id)
              },
              // {
              //   name: "Record Interaction",
              //   action: () => this.recordInteraction(data.node.data.tenant_id)
              // }
            ]
          });
        }
        /*
        if(data.node.data.property_id){
            items.push({
                name: "Facilities",
                subMenu: [
                    {
                        name: "View Facility",
                        action: () => this.viewEntity('property', data.node.data.property_id)
                    }
                ]
            });
        }
        */

        if(data.node.data.maintenance_id){

          items.push({
            name: "Maintenance",
            subMenu: [
              {
                name: "View Maintenance",
                action: () =>  this.viewMaintenance(data.node.data.maintenance_id)
              }
            ]
          });
        }

        // BCT: hide 'space info' from Spaces screen. (on right click)
        if(data.node.data.unit_id && this.report_type !== 'spaces'){
          items.push({
            name: 'Spaces',
            subMenu: [
              {
                name: "View Space Info",
                action: () => this.viewEntity('unit', data.node.data.unit_id)
              },
              {
                name: "Send Space Info",
                action: () => this.sendSpaceInfo(data.node.data.unit_id)
              },
            ]
          });
        }
        if(data.node.data.invoice_id){
          items.push({
            name: 'Invoice',
            subMenu: [
              {
                name: "View Invoice Info",
                action: () => this.viewEntity('invoice', data.node.data.invoice_id)
              }
            ]
          });
        }
        if(data.node.data.payment_id){

          items.push({
            name: 'Payment',
            subMenu: [
              {
                name: "View Payment Info",
                action: () => this.viewEntity('payment', data.node.data.payment_id)
              }
            ]
          });
        }
        if(data.node.data.lease_id && data.node.data.tenant_id){
          let submenu = {
            name: 'Lease',
            subMenu: [
              {
                name: "Go To Lease",
                action: () => this.viewEntity('lease', data.node.data.tenant_id, data.node.data)
              }
            ]
          };

          if(data.node.data.lease_id && data.node.data.tenant_id){
            submenu.subMenu.push(
              {
                name: "Take Payment",
                action: () => this.takePayment({
                  contact_id: data.node.data.tenant_id,
                  lease_id: data.node.data.lease_id
                })
              },
            )
          }
          items.push(submenu)
        }


      }

      // items = items.concat([
      //
      // ]);
      return items;
    },

    recordInteraction(contact_id){

      this.actions.type = 'recordInteraction';
      this.addInteractionModal = true;
      this.actions.props.contact_id = contact_id;

    },

    sendMessage(contact_id){


      this.sendMessageDialog = true;
      this.actions.props.contact_id = contact_id;

    },

    sendSpaceInfo(unit_id){
      this.actions.type = 'sendSpaceInfo';
      this.sendSpaceInfoModal = true;
      this.actions.props.unit_id = unit_id;

    },

    viewMaintenance(maintenance_id){
      this.actions.type = 'viewMaintenance';
      this.viewMaintenanceModal = true;
      this.actions.props.maintenance_id = maintenance_id;
    },

    async takePayment(data){

      let r = await api.get(Vue, api.LEASES + data.lease_id);
      EventBus.$emit('make_payment', {
        contact_id: data.contact_id,
        property_id: r.lease.Unit.property_id
      })
    },

    viewEntity(entity, entity_id, entity_data){
      let params = {};
      switch(entity){
        case 'lease':
          let path = `/contacts/${entity_id}`
          if(entity_data?.unit_id) path += `?unit_id=${entity_data.unit_id}`;
          this.$router.push(path);
          break;
        case 'tenant':
          params = {
            component: 'contact',
            props: {
              contact_id: entity_id
            }
          };
          this.$store.dispatch('navigationStore/openSlideOut', params);
          break;
        // case 'payment':
        case 'payment_method':
        case 'activity':
          break;
        default:
          params = {
            component: entity,
            props: {}
          };
          params.props[entity + '_id'] = entity_id;
          this.$store.dispatch('navigationStore/openSlideOut', params);
          break;
      }
    },

    moveIn(contat_id){

      //this.$router.push('/leases/' + lease_id);
    },

    // sets colors, etc
    setCellStyle(params, column){

      if (this.report.filters.pivot_mode.type) {
        return {
          lineHeight: '20px',
          paddingTop: '10px',
          paddingBottom: '10px',
          color: '#101318',
          backgroundColor: 'none'
        }
      }
      return {
        lineHeight: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        color: this.balanceFields.indexOf(params.colDef.field) >= 0 && params.value && parseFloat(params.value) > 0 ? '#D91E18' : '#101318',
        backgroundColor: this.balanceFields.indexOf(params.colDef.field) >= 0 && params.value && parseFloat(params.value) > 0 ? 'rgba(217,30,24,.1)' : 'none'
      }




    },

    // getCellValue(params){
    //
    //     if(!params.data) return false;
    //     if(this.filters.pivot_mode.enabled) return params.data[params.colDef.field];
    //
    //     switch(params.colDef.field){
    //         // case 'lease_status':
    //         //     if(params.data[params.colDef.field] === 1){
    //         //         return 'Active';
    //         //     }
    //         //     break;
    //         case 'unit_status':
    //             if(params.data[params.colDef.field] === 1){
    //                 return 'Online';
    //             } else if(params.data[params.colDef.field] === 0) {
    //                 return 'Offline';
    //             }
    //             break;
    //
    //         case 'application_status':
    //             if(params.data[params.colDef.field] === 1){
    //                 return 'Active';
    //             } else if(params.data[params.colDef.field] === 2){
    //                 return 'Rejected';
    //             } else if(params.data[params.colDef.field] === 3){
    //                 return 'Accepted';
    //             }
    //             break;
    //     }
    //     // used to convert status ints into words like "Closed" or something
    //     return params.data[params.colDef.field];
    // },

    // Formats based on columns type..  e.g. money, date, etc.
    formatCellValue(params, col){

      let root, address, value;

      if(col.group === 'pivot'){
        if(col.column_type === 'money'){
          if(params.value === null) value = "$0.00";
          if(typeof params.value === 'number'){
            return formatMoneyByCurrency(params.value, 2)
          } else {
            return '$' + params.value;
          }
        } else {
          return this.$options.filters.formatMoneyWithComma(params.value, 2);
        }
      }

      switch(col.column_type){
        case 'money':
          if(params.value === null || params.value === '') return "";
          if(typeof params.value === 'number'){
            value = formatMoneyByCurrency(params.value)
          } else {
            value = '$' + params.value;
          }
          break;
          case 'html_content':
            value = this.$options.filters.removeHtml(params.value);
            break;
        case 'percentage':
          if (params.value === 'N/A') value = 'N/A'
          else if(params.value === null) value = "";
          else if(params.value > 0){
            value = '+' + (params.value * 100).toFixed(2) + '%';
          } else if(params.value < 0){
            value = (params.value * 100).toFixed(2) + '%';
          }else{
            value = params.value + '%';
          }
          break;
        case 'days':
          if(params.value === null) return "0 Days";
          value = params.value + ' Days';
          break;
        case 'phone':
          if(this.report.filters.pivot_mode.type) return params.value;
          value = this.$options.filters.formatPhone(params.value);
          break;
        // // Moving address html rendereing formatter to columnConfigMixin  ->> start here
        // case 'string':
        //   if(params.value === null) return "";
        //   value= col.format ? `<span style="text-transform: capitalize;">${params.value}</span>` : params.value;
        //   break;

        // case 'concat':
        //   if(this.report.filters.pivot_mode.type) return params.value;
        //   if(!params.value) return '';
        //   let v = params.value.split(',');
        //   if(!v.length) return '';
        //   // BCT: Modify number of values to be displayed.
        //   value = v.slice(0, 4).join(', ');
        //   if(v.length > 4){
        //     value += ' <span style="color: #a0a0a0"> + ' + (v.length - 4) + ' more</span>';
        //   }
        //   if(col.format == "Recipient"){
        //     value = v[0];
        //     let customItems = params.value.split(',');
        //     value = value == "Local" ? "Local": `<span style="border-bottom: 1px dashed"> ${(customItems.length)} ${col.format}</span>`;
        //   }

        //   break;
        // // Moving html rendereing formatter to columnConfigMixin <<- ends here
        case 'date':
          if(params.value === null) {
            value = '-';
          } else {
            value = col.format ? this.$options.filters.formatDateCustom(params.value, col.format) : this.$options.filters.formatDate(params.value);
          }
          break;
        case 'date2':
          if(params.value === null) {
            value = '-';
          } else {
            value =  this.$options.filters.formatDateTimeCustom(params.value,'YYYY-MM-DD h:mm:ss A');
            value =  col.format ? this.$options.filters.formatDate(params.value, col.format): this.$options.filters.formatDate(value);
          }
          break;
        case 'datetime':
          if(params.value === null) return;
          value = this.$options.filters.formatLocalDateTime(params.value);
          break;

        // // Moving address html rendereing formatter to columnConfigMixin  ->> start here
        // case 'address':
        //   root = params.colDef.colId.substring(0,params.colDef.colId.lastIndexOf('_'));
        //   address = params.value || null;
        //   if(address){
        //     if(params.data[root + '_address2']) address += ' #' + params.data[root + '_address2'];
        //     if(params.data[root + '_city'] || params.data[root + '_state'] || params.data[root + '_zip']){
        //       address += "<br />";
        //       if(params.data[root + '_city']) address += params.data[root + '_city'] + ' ';
        //       if(params.data[root + '_state']) address += params.data[root + '_state'] + ' ';
        //       if(params.data[root + '_zip']) address += params.data[root + '_zip'];
        //     }
        //   }
        //   value = address;
        //   break;
        // // Moving html rendereing formatter to columnConfigMixin <<- ends here

        default:
          value = params.value;

      }
      return value


      // used to convert status ints into words like "Closed" or something

    },

    // injects HTMl into the markup to display nice looking values, icons, etc.
    renderCellValue(params, col){
      if((!this.report.filters.pivot_mode || !this.report.filters.pivot_mode.type)) {

        switch(col.key) {
          case 'unit_number':
            const componentClass = Vue.extend(HbUnitIcon);
            const instance = new componentClass({propsData: { unit: params.data, small: true, color: '#101318' }});
            const unitIcon = instance.$mount();
            const unitIconHTML = unitIcon.$el.innerHTML;
            return  params.value ? `<div>${unitIconHTML} <span>#${params.value}</span></div>` : `<div>${unitIconHTML} <span> - </span></div>`;
            break;
          case 'unit_overlocked':
            const componentClass2 = Vue.extend(HbStatusGeneral);
            const instance2 = new componentClass2({propsData: { status: params.valueFormatted }});
            const doorStatus = instance2.$mount();
            const doorStatusHTML = doorStatus.$el.innerHTML;
            return params.value ? `<div class="font-weight-medium">${doorStatusHTML}</div>` : `<div>-</div>`;
            break;
        }

        switch(col.column_type){

          case 'status':
            if(!params.valueFormatted) return '';
            var currentSelection = this.statusTypes.filter( i => String(params.valueFormatted).toLowerCase() === i.name.toLowerCase() );
            var color = '';
            if(currentSelection[0]){
              color = currentSelection[0].color;
            } else {
              color = '#919EAB';
            }
            return '<span class="hb-v-chip v-chip v-chip--outlined theme--light v-size--small" style="background-color: rgb(255, 214, 0); border-color: ' + color +'; color: ' + color + '; caret-color: rgb(255, 214, 0);"><span class="v-chip__content"><div class="v-avatar mr-0 v-avatar--left" style="height: 48px; min-width: 48px; width: 48px;"><i aria-hidden="true" class="v-icon notranslate mdi mdi-checkbox-blank-circle theme--light" style="font-size: 14.5px !important;"></i></div><span class="hb-status-font text-capitalize font-weight-medium" style="letter-spacing:normal;">' + params.valueFormatted + '</span></span></span>';
            break;
          case 'boolean':
            return params.value ? '<span><span class="icon"></span> Yes</span>': '<span><span class="icon"></span> No</span>';
            break;
        }
      }
      return params.valueFormatted;
    },

    handleCellClick(data){
    this.lastClickedColDef = data;
      if(this.active_action === 'bulk_edit') return;

      if( ( !this.report.filters.pivot_mode || !this.report.filters.pivot_mode.type) && !this.no_cell_click) {
        switch(data.colDef.colId){
          case 'property_name':
            //       return this.viewEntity('property', data.node.data.property_id);
            break;
          case 'unit_number':
            // if(this.report_type === 'tenants'){
            //     this.viewEntity('lease', data.node.data.lease_id)
            // } else {
            //     return this.viewEntity('unit', data.node.data.unit_id);
            // }
            break;
          case 'lead_first':
          case 'lead_last':
          case 'lead_name':
            // return this.viewEntity('contact', data.node.data.lead_id);
            break;
          case 'contact_first':
          case 'contact_last':
          case 'contact_name':
          case 'tenant_first':
          case 'tenant_last':
          case 'tenant_name':
//                            return this.viewEntity('contact', data.node.data.tenant_id);
          case 'tenant_email':
          case 'tenant_phone':
            //                          this.sendMessage(data.node.data.tenant_id)
            break;
        }
      }
      this.$emit('cell_click', data);
    },

    handleDragStopped(data){

      let columns = data.columnApi.getAllGridColumns();

      this.$store.commit('reportStore/updateColumnPosition', {
        report_template: this.report.template,
        report_id: this.report.id,
        columns: columns
      });

      // this.report.filters.columns.sort((a,b) => columns.findIndex(col => col.colDef.field === a.key) < columns.findIndex(col => col.colDef.field === b.key) ? -1 : 1);

      // this.filters.columns.forEach(c => {
      //     c.width = columns[columns.findIndex(col => col.colDef.field === c.key)].actualWidth;
      //     console.log(c.label, c.width);
      // })
    },

    handleColumnSize(data){

      this.$store.commit('reportStore/setColumnSize', {
        report_template: this.report.template,
        report_id: this.report.id,
        data: data
      });


      // this.filters.columns = this.filters.columns.map(c => {
      //     if(c.key === data.column.colDef.field){
      //         c.width = data.column.actualWidth
      //     }
      //     return c;
      // });
    },

    handleRowClick(data){
      if(!this.row_click) {
       if(!this.lastClickedColDef?.colDef?.pinned) {
        this.$emit('rowClicked', data.data);
       }
      }
      if(this.active_action === 'bulk_edit') return;

      switch(this.row_click){
        case 'unit_view':
          this.unit_id = data.data.unit_id;
          if(!this.unit_id) return;
          this.active_action = 'unit_view';
          break;
        case 'contact_view':
          this.contact_id = data.data.contact_id || data.data.lead_id || data.data.tenant_id;
          if(!this.contact_id) return;
          this.active_action = 'contact_view';
          break;
      }

    },


    onRowSelected(evt){
      let selected = evt.node.isSelected();
if(this.getTenantReportViews.includes(this.report_type)) {
        if(selected)
          this.selectedRows.push(evt.node.data)
        else
          this.selectedRows.splice(this.selectedRows.findIndex(item => item.lease_id === evt.node.data.lease_id), 1);
      }
      if(this.$props.conditions && this.$props.conditions.select_all) {
        if(!selected) {
          this.un_checked.push(evt.node.data.lease_id);
        } else {
          this.un_checked.splice(this.un_checked.indexOf(evt.node.data.lease_id), 1);
        }
      }
      if(!selected) {
        this.gridApi.clearFocusedCell();
      }
      //
      //     if(this.checkboxes_disabled){
      //         var selected = evt.node.isSelected();
      //         if(selected){
      //             evt.node.setSelected(false);
      //         } else {
      //             evt.node.setSelected(true);
      //         }
      //         alert("you cant change this selection once you've selected a bulk action.");
      //     }
      //   // console.log(evt);
    },

    onSelectionChanged(){
      //if(!this.checkboxes_disabled){
      this.selected = this.gridApi.getSelectedRows();
if(this.report_type == 'tenant_rent_management') {
        this.selected = this.selectedRows
      }
      if(this.$props.conditions && this.$props.conditions.select_all) {
        this.$emit('selectedRows', this.un_checked);
      } else if (this.bulkCommunication) {
        this.$emit('selectedRows', this.selected);
      }
      //}
    },

    // onSelectionChanged:  _.debounce(async function(params){
    //     console.log("selection changed", this.gridApi.getSelectedRows());
    //    this.setItems(this.gridApi.getSelectedRows());
    // }, 300),

    resetBulkEditSelection(){
      this.checkboxes_disabled = false;
      this.gridApi.deselectAll();
    },

    // BCT: Set Footer component
    setFooter() {
      this.gridOptions.statusBar = {
        statusPanels: [
          {
            key: 'Footer',
            statusPanel: 'Footer',
            statusPanelParams: {
              count: 0
            },
            align: 'left'
          }
        ]
      }
    },

    // BCT: Set footer params and filtered data.
    setFooterParams() {
      const statusBarComponent = this.gridApi.getStatusPanel( 'Footer' );
      if (statusBarComponent) {
        statusBarComponent.setFooterParams(this.num_results, this.report_type, this.total_count, this.report);
      }
    },

    // BCT: Handle select all rows
    handleSelectAll(selected) {
      if (this.gridApi) {
        this.gridApi.forEachNode(n => n.setSelected(selected));
      }
    },

  },

  destroyed() {
    if(this.default_column_filters.length) {
      this.default_column_filters.forEach(data => {
        this.$store.commit('reportStore/setColumnFilter', {
          report_id: this.active_report_id,
          report_template: this.report_type,
          key: data.id,
          search: []
        }); // clear default filter
      })
    }

    this.temp_result_count = 0
    this.num_results = 0
    this.total_count = 0
    
    EventBus.$off("reset_grid_count_values", () => {
      this.total_count = 0;
      this.resetGridRowsCount();
    });

  }, // clear default search filter

  // BCT: Added to show Selected rows count in footer status bar
  watch: {
    selected() {
      if (this.gridApi) {
        const statusBarComponent = this.gridApi.getStatusPanel( 'Footer' );
        if (statusBarComponent) {
          if (statusBarComponent) {
            statusBarComponent.setSelectedRowsCount(this.active_action, this.selected);
          }
        }
      }
    }
  }
};

